<template>
  <dash-page-new
      :title="newsletter.name || ''"
      :root="$t('mailer.single.title')"
      :root-location="businessDashLink('fine_mailer/letters')"
      :subtitle="$t('Preview')"
      background-color="var(--wsLIGHTCARD)"
      no-body-padding
  >
    <template #header_action>

      <div class="d-flex align-center">
        <ft-select
            :items="headerActionsSelect"
            :disabled="headerActionsSelect.length === 0"
        >
          <ws-button
              :color="wsACCENT"
              :left-icon-color="NEWSLETTER_STATUS_COLOR(newsletter.status)"
              :click-stop="false"
              :icon="!!headerActionsSelect.length ? 'mdi-menu-down' : null"
              left-icon="mdi-circle-medium"
              :label="newsletter.status ? `mailer.campaigns.status.${newsletter.status}` : 'Status'"
              outlined
          />
        </ft-select>

        <ws-button
            v-if="displayEditButtonCondition"
            @click="openEditNewsletter"
            label="Edit"
            class="ml-2"
        />

      </div>




    </template>

    <template #default>
      <div class="d-flex flex-column fill-height">


        <!-- Notifications -->
        <v-sheet
            v-if="newsletter.status === 'usererror'"
            :color="wsATTENTION"
            class="px-4 mx-10 mt-4 py-2 wsRoundedLight d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <v-icon :color="wsWHITETEXT" class="mr-2">mdi-information-outline</v-icon>
            <h5 class="wsWHITETEXT">{{ $t(`mailer.campaigns.usererror.reason.${newsletter.reason}`) }}</h5>
          </div>

          <ws-link v-if="displayWarningPaymentCondition" :to="businessDashLink('plans_payments')" new-window class="mr-n4 ml-4">
            <ws-button  label="Payment" left-icon="mdi-credit-card-marker" :color="wsWHITE" text />
          </ws-link>

        </v-sheet>



        <!-- Header-->
        <v-sheet class="mt-5" color="transparent">


          <!-- Statistics -->
          <div class="d-flex flex-row justify-space-between "
               :class="[
                   { 'px-10' : !SM } ,
                   { 'px-4'  : SM }
               ]"
          >

            <v-sheet
                v-for="(item,i) in statisticsSelect" :key="i"
                style="border: 1px solid var(--wsBACKGROUND);"
                class="wsRoundedSemi flex-grow-1 pa-4"
                :class="[{'ml-3' : i !== 0 }]"
            >
              <v-icon :color="wsACCENT">{{ item.icon }}</v-icon>
              <h5 style="font-size: 12px" class="py-1 wsACCENT">{{ item.title }}</h5>
              <h3 style="font-size: 19px" class="wsACCENT">
                {{ item.text }}
                <span class="font-weight-regular" v-if="item.text_regular">({{ item.text_regular }})</span>
              </h3>
            </v-sheet>

          </div>
          <ws-navigation-header
              :items="navigationSelect"
              v-model="navigation"
              class="mt-4"
              padding="2px"
          />
        </v-sheet>

        <!-- Content -->
        <v-sheet
            :class="[{ 'px-10' : !SM },{ 'px-4'  : SM }]"
            class="flex-grow-1 fill-height"
        >
          <ws-navigation
              :items="navigationSelect"
              v-model="navigation"
              hide-header
          >
            <template #item.receivers>

              <v-sheet class="wsRoundedLight mt-2" style="border : 1px solid var(--wsBACKGROUND);">
                <ws-data-table
                    :items="receivers"
                    :headers="headers"
                    :no-footer="!receivers.length"
                >
                  <template #item.name="{item}">
                    <h5 class="wsACCENT">
                      <v-icon :color="getReceiverColor(item.status)"  >mdi-circle-medium </v-icon>
                      {{ item.name }}
                    </h5>
                  </template>

                  <template #item.date="{item}">
                    <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date) }}</h5>
                  </template>

                  <template #item.opened="{item}">
                    <h5 class="text-center">{{ item.opened }}</h5>
                  </template>

                  <template #item.clicks="{item}">
                    <h5 class="text-center">{{ item.clicks }}</h5>
                  </template>

                  <template #item.status="{item}">
                    <ws-chip min-width="120" class="mx-auto" outlined :color="wsACCENT" :text="$t(`mailer.receivers.status.${item.status}`)"></ws-chip>
                  </template>

                </ws-data-table>
                <div class="py-16">
                  <div class="d-flex justify-center">
                    <v-icon size="80" :color="wsDARKLIGHT">mdi-account-multiple</v-icon>
                  </div>

                  <h3 class="wsDARKER text-center">{{ $t('mailer.newsletter.no_receivers_title') }}</h3>

                </div>
              </v-sheet>


            </template>

            <template #item.links>

              <ws-data-table
                  class="mt-2"
                  :items="links"
                  :headers="linksHeaders"
              >
              </ws-data-table>

            </template>

            <template #item.design>
              <email-viewer
                  :uuid="newsletter.newsletter_design_id"
              />
            </template>

            <template #item.info>
              <h4 v-for="(value,key) in newsletter" class="wsDARKER" :key="key">
                {{ key }} : <span class="font-weight-regular"> {{ value }}</span>
              </h4>
            </template>

          </ws-navigation>
        </v-sheet>

      </div>
    </template>

    <template #dialog>
      <start-newsletter-dialog
          v-if="displayStartDialog"
          v-model="displayStartDialog"
          :newsletter="newsletter"
          no-redirect
      />

      <newsletter-wizard
          v-if="displayEdit"
          v-model="displayEdit"
          :newsletter="newsletter"
          single
          from-letter
      />
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import finemailer from "@/modules/finemailer/mixins/finemailer";
import EmailViewer from "@/modules/finemailer/components/emailConstructor/viewer/EmailViewer.vue";
import NewsletterWizard from "@modules/finemailer/components/newsletterWizard/NewsletterWizard.vue";
import startNewsletterDialog from "@modules/finemailer/components/campaigns/startNewsletterDialog.vue";
export default {
  name: "mailerLetters",
  mixins : [finemailer],
  components : {
    NewsletterWizard,
    EmailViewer,
    startNewsletterDialog
  },
  props : {
    uuid : {
      type : String,
    }
  },
  data() {
    return {
      navigation : 'receivers',

      newsletter : {},
      emailDesign : {},
      designReady : false,

      totalReceivers : 0,
      totalSent : 0,
      totalOpened : 0,
      totalLinksClicks : 0,
      totalUnsubscribed : 0,
      totalBounced : 0,

      stats : {},
      receivers : [],
      links : [],

      displayEdit : false,
      displayStartDialog : false

    }
  },
  computed : {
    displayWarningPaymentCondition() {
      return [
          'not_payed',
          'per_campaign_limit',
          'per_day_limit'
      ].includes(this.newsletter.reason)
    },

    displayEditButtonCondition() {
      if (this.newsletter.status === 'usererror') {
        return true
      }

      return false
    },
    headers() {
      return [
        { text : this.$t('Contact')  , value : 'name'     } ,
        { text : this.$t('Email'  )  , value : 'email'    },
        { text : this.$t('Date'   )  , value : 'date'     ,width : '10px'},
        { text : this.$t('mailer.campaigns.opened' )  , value : 'opened'   ,width : '10px'},
        { text : this.$t('mailer.campaigns.link_clicks' )  , value : 'clicks'   ,width : '10px'},
        { text : this.$t('Status' )  , value : 'status' ,width : '10px' },
      ]
    },
    linksHeaders() {
      return [
        { text : this.$t('Email')     , value : 'url'     } ,
        { text : this.$t('Clicks')  , value : 'clicks' , width : '10px'   },
      ]
    },


    isAutomation() {
      return this.newsletter.is_automation
    },
    statisticsSelect() {
      return [
        { icon : 'mdi-account-multiple',
          title : this.$t('mailer.stats.total'),
          value : 'total',
          text : this.totalReceivers,
        },
        // check-circle-outline
        { icon : 'mdi-email-check-outline',
          title : this.$t('mailer.stats.delivered'),
          value : 'delivered',
          text : this.totalSentPercent,
          text_regular : this.totalSent,
        },
        { icon : 'mdi-email-open-outline',
          title : this.$t('mailer.stats.opened'),
          value : 'opened',
          text : this.totalOpenedPercent,
          text_regular : this.totalOpened,
        },
        { icon : 'mdi-open-in-new',
          title : this.$t('mailer.stats.links_opened'),
          value : 'clicks',
          text : this.totalLinksClicksPercent,
          text_regular : this.totalLinksClicks,
        },
        { icon : 'mdi-close-circle-outline',
          title : this.$t('mailer.stats.unsubscribed'),
          value : 'unsubscribe',
          text : this.totalUnsubscribedPercent,
          text_regular : this.totalUnsubscribed,
        },
        { icon : 'mdi-email-remove-outline',
          title : this.$t('mailer.stats.bounced'),
          value : 'sending',
          text : this.totalBouncedPercent,
          text_regular : this.totalBounced,
        }
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('mailer.campaigns.receivers') , value : 'receivers' },
        { text : this.$t('Link') , value : 'links' },
        { text : this.$t('mailer.preview_list') , value : 'design' },
        { text : this.$t('mailer.newsletter.info') , value : 'info' }
      ]
    },
    headerActionsSelect() {
      let items = []
      if (!this.newsletter.is_automation ) {
        if ( this.newsletter.status === 'usererror' ) {
          items = [
            { text : this.$t('SendNewsletterAgain') ,
              action : this.startNewsletter,
              icon : 'mdi-email-arrow-right-outline'
            },
          ]
        }
      }else {
        items =  [
          { text : this.$t('PauseNewsletter') , action : this.pauseNewsletter },
          { text : this.$t('PauseNewsletter') , action : this.pauseNewsletter }
        ]
      }


      return items
    },

    // Statistics percents counting

    totalSentPercent() {
      if (!this.totalSent || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalSent/this.totalReceivers)*100) + '%'
    },
    totalOpenedPercent() {
      if (!this.totalOpened || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalOpened/this.totalReceivers)*100) + '%'
    },
    totalLinksClicksPercent() {
      if (!this.totalLinksClicks || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalLinksClicks/this.totalReceivers)*100) + '%'
    },
    totalUnsubscribedPercent() {
      if (!this.totalUnsubscribed || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalUnsubscribed/this.totalReceivers)*100) + '%'
    },
    totalBouncedPercent() {
      if (!this.totalBounced || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalBounced/this.totalReceivers)*100) + '%'
    },
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'START_NEWSLETTER',
      'GET_NEWSLETTER_STATS'
    ]),

    startNewsletter() {
      this.displayStartDialog = true
    },
    openEditNewsletter() {
      this.displayEdit = true
    },

    pauseNewsletter() {
      this.notify('pauseNewsletter')
    },
    //Techincal

    getReceiverColor(status) {
      switch(status) {
        case 'sent': return this.wsSUCCESS
        case 'bounced' : return this.wsWARNING
        case 'unsubscribed' : return this.wsATTENTION
        default : return this.wsDARKLIGHT
      }
    },
    // Init Data functions
    async initPage() {
      let result  = await this.GET_NEWSLETTER(this.uuid)
      if (!result) {
        return this.ERROR()
      }
      this.newsletter = result

    },
    async getStatistics() {
      let result = await this.GET_NEWSLETTER_STATS(this.uuid)
      if ( !result) {
        return this.ERROR()
      }
      this.receivers = result.items
      this.links = result.newsletterLinks

      this.totalReceivers    = result.total         || 0
      this.totalSent         = result.sent          || 0
      this.totalOpened       = result.opened        || 0
      this.totalLinksClicks  = result.clicks        || 0
      this.totalUnsubscribed = result.unsubscribed  || 0
      this.totalBounced      = result.failed        || 0
    }
  },
  mounted() {
    this.initPage()
    this.getStatistics()
  }


}
</script>

<style scoped>

</style>